.users-appear {
  opacity: 0.01;
  margin-top: 20px;
}
.users-appear.users-appear-active {
  opacity: 1;
  margin-top: 0px;
  transition: all 500ms ease-out;
}
.users-enter {
  opacity: 0.01;
  margin-top: 20px;
}
.users-enter.users-enter-active {
  opacity: 1;
  margin-top: 0px;
  transition: all 500ms ease-out;
}
.users-exit {
  opacity: 1;
  margin-top: 0px;
}
.users-exit.users-exit-active {
  opacity: 0.01;
  margin-top: -20px;
  transition: all 500ms ease-out;
}

.abs-appear {
  opacity: 0.01;
  top: 50px;
}
.abs-appear.abs-appear-active {
  opacity: 1;
  top: 100px;
  transition: all 500ms ease-out;
}
.abs-enter {
  opacity: 0.01;
  top: 50px;
}
.abs-enter.abs-enter-active {
  opacity: 1;
  top: 100px;
  transition: all 500ms ease-out;
}
.abs-exit {
  opacity: 1;
  top: 100px;
}
.abs-exit.abs-exit-active {
  opacity: 0.01;
  top: 50px;
  transition: all 5000ms ease-out;
}

.input-enter {
  opacity: 0.01;
  margin-top: 20px;
}
.input-enter.input-enter-active {
  opacity: 1;
  margin-top: 0px;
  transition: all 500ms ease-out;
}
.input-exit {
  opacity: 1;
  margin-top: 0px;
}
.input-exit.input-exit-active {
  opacity: 0.01;
  margin-top: -20px;
  transition: all 500ms ease-out;
}

.result-appear {
  opacity: 0.01;
  transform: scale(0.0);
}
.result-appear.result-appear-active {
  opacity: 1;
  transform: scale(1.0);
  transition: all 1000ms ease-out;
  transition-delay: 2000ms;
}
.result-enter {
  opacity: 0.01;
  margin-top: 500px;
}
.result-enter.result-enter-active {
  opacity: 1;
  margin-top: 0px;
  transition: all 1000ms ease-out;
  transition-delay: 2000ms;
}
.result-exit {
  opacity: 1;
  margin-top: 0px;
}
.result-exit.result-exit-active {
  opacity: 0.01;
  margin-top: 500px;
  transition: all 1000ms ease-out;
  transition-delay: 2000ms;
}